<script setup>
import {
  computed,
  onMounted,
  ref,
  watch,
} from 'vue';
import useInput from './useInput';

const {
  handleOnMounted,
  handleOnFocus,
  computedInputAttrs,
  computedIsRequired,
  computedRules,
  input,
  inputValue,
  watchFocus,
  watchModelValue,
} = useInput();

const props = defineProps(myProps);

const showPassword = ref(false);

const inputAttrs = computed(computedInputAttrs(props, ['type']));
const isRequired = computed(computedIsRequired(props));
const myRules = computed(computedRules(props));
const buttonIcon = computed(() => showPassword.value ? ['fas', 'eye-slash'] : ['fas', 'eye']);
const inputType = computed(() => showPassword.value ? 'text' : 'password');

const emit = defineEmits(['update:modelValue']);

watch(inputValue, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    emit('update:modelValue', newValue);
  }
});
watch(() => props.focus, watchFocus);
watch (() => props.modelValue, watchModelValue);

onMounted(handleOnMounted);

const toggleShowPassword = () => {
  if (!props.disabled) {
    showPassword.value = !showPassword.value;
  }
};
</script>

<script>
// defineProps() cannot reference locally defined variables so the default props from useInput()
// are defined at the module level.
// SEE:
// https://stackoverflow.com/questions/69951687/vue-3-defineprops-are-referencing-locally-declared-variables
// https://eslint.vuejs.org/rules/valid-define-props.html

const { getDefaultProps } = useInput();
const myProps = getDefaultProps();
</script>

<template>
  <Field
    v-slot="{ errors, handleChange, resetField }"
    v-model="inputValue"
    as="div"
    :name="name"
    :rules="myRules"
    :validate-on-input="true"
  >
    <InputWrapper
      :disabled="disabled"
      :errors="errors"
      :hide-empty-errors="hideEmptyErrors"
      :full-width="fullWidth"
      :label="label"
      :name="name"
    >
      <div class="tw-inline-block tw-w-full tw-flex tw-place-content-between">
        <input
          ref="input"
          v-model="inputValue"
          v-bind="inputAttrs"
          class="tw-inline-block tw-w-full tw-outline-none bg-inherit"
          :class="inputClass"
          :required="isRequired"
          :type="inputType"
          @blur="handleChange"
          @focus="handleOnFocus(resetOnFocus, resetField)"
        >
        <span
          :class="[
            'tw-outline-none',
            'tw-w-8',
            'tw-inline-block tw-flex tw-items-center tw-justify-center',
            disabled ? 'tw-cursor-default' : 'tw-cursor-pointer',
          ]"
          @click.stop.prevent="toggleShowPassword"
        >
          <font-awesome-icon
            class="text-light-color tw-h-4"
            :icon="buttonIcon"
          />
        </span>
      </div>
    </InputWrapper>
  </Field>
</template>
